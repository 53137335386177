import "./styles/index.css";
// dropdown

window.dropdownToggle = function () {
  document.getElementById("dropdown-options").classList.toggle("dropdown");
};

// modals
const modal = document.getElementById("modal");
const modal2 = document.getElementById("modal2");
const modalOpen = document.getElementById("open-modal");
const modalClose = document.getElementById("close-modal");
const modalClose2 = document.getElementById("close-modal2");
const modalRegister = document.getElementById("create-account");

modalOpen.addEventListener("click", function openModal() {
  modal.style.display = "block";
});
modalClose.addEventListener("click", function closeModal() {
  modal.style.display = "none";
});

modalClose2.addEventListener("click", function closeModal2() {
  modal2.style.display = "none";
});
modalRegister.addEventListener("click", function registerModal() {
  modal2.style.display = "block";
});

// loader
window.addEventListener("load", () => {
  const loadingDiv = document.getElementById("loading");
  loadingDiv.style.display = "none";
  // console.log("Loading complete");
});

// Get the modal elements
var modalOverlay = document.querySelector(".modal-overlay");
var modalWindow = document.querySelector(".modal-window");
var closeButton = document.querySelector(".close-button");
// Open the modal window when the privacy policy link is clicked
document
  .getElementById("privacy-policy-link")
  .addEventListener("click", function () {
    modalOverlay.classList.add("visible");
    modalWindow.classList.add("visible");
  });
// Close the modal window when the close button or the overlay is clicked
closeButton.addEventListener("click", closeModal3);
modalOverlay.addEventListener("click", closeModal3);
function closeModal3() {
  modalOverlay.classList.remove("visible");
  modalWindow.classList.remove("visible");
}
